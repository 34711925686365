import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"
import Layout from "../components/layout"
import HeroImage from "../components/heroImage"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import GoTo from "../components/goTo"

const ExpertisePage = ({ data }) => {
  const intl = useIntl()
  return (
    <React.Fragment>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "expertise.title" })}
        description={intl.formatMessage({ id: "expertise.caption" })}
      />

      <Layout intl={intl}>
        <HeroImage
          image={data.expertise}
          title={intl.formatMessage({ id: "expertise.title" })}
          caption={intl.formatMessage({ id: "expertise.caption" })}
        />
        <section className="container mx-auto px-5 my-20">
          <div className="-mx-5 flex flex-wrap">
            <div className="px-5 w-full lg:w-1/2">
              <div className="relative w-full" style={{ paddingTop: "56.25%" }}>
                <Img
                  className="inset-0 h-full w-full"
                  style={{ position: "absolute" }}
                  fluid={data.activities.childImageSharp.fluid}
                />
              </div>
            </div>
            <div className="my-auto px-5 w-full lg:w-1/2">
              <h2 className="text-5xl font-light text-green-600">
                {intl.formatMessage({
                  id: "expertise.first_expertise_subtitle",
                })}
              </h2>
              <ul
                className="list-disc text-lg font-light text-gray-700 ml-5"
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "expertise.first_expertise_copy_html",
                  }),
                }}
              ></ul>
            </div>
          </div>
        </section>
        <section className="container mx-auto px-5 my-20">
          <div className="-mx-5 flex flex-wrap">
            <div className="my-auto px-5 w-full lg:w-1/2 order-2 lg:order-1">
              <h2 className="text-5xl font-light text-green-600">
                {intl.formatMessage({
                  id: "expertise.second_expertise_subtitle",
                })}
              </h2>
              <ul
                className="list-disc text-lg font-light text-gray-700 ml-5"
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "expertise.second_expertise_copy_html",
                  }),
                }}
              ></ul>
            </div>
            <div className="px-5 w-full lg:w-1/2 order-1 lg:order-2">
              <div className="relative w-full" style={{ paddingTop: "56.25%" }}>
                <Img
                  className="inset-0 h-full w-full"
                  style={{ position: "absolute" }}
                  fluid={data.activities.childImageSharp.fluid}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="container mx-auto px-5 my-20">
          <div className="-mx-5 flex flex-wrap">
            <div className="px-5 w-full lg:w-1/2">
              <div className="relative w-full" style={{ paddingTop: "56.25%" }}>
                <Img
                  className="inset-0 h-full w-full"
                  style={{ position: "absolute" }}
                  fluid={data.activities.childImageSharp.fluid}
                />
              </div>
            </div>
            <div className="my-auto px-5 w-full lg:w-1/2">
              <h2 className="text-5xl font-light text-green-600">
                {intl.formatMessage({
                  id: "expertise.third_expertise_subtitle",
                })}
              </h2>
              <ul
                className="list-disc text-lg font-light text-gray-700 ml-5"
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "expertise.third_expertise_copy_html",
                  }),
                }}
              ></ul>
            </div>
          </div>
        </section>
        <section className="container mx-auto px-5 my-20">
          <div className="-mx-5 flex flex-wrap">
            <div className="my-auto px-5 w-full lg:w-1/2 order-2 lg:order-1">
              <h2 className="text-5xl font-light text-green-600">
                {intl.formatMessage({
                  id: "expertise.fourth_expertise_subtitle",
                })}
              </h2>
              <ul
                className="list-disc text-lg font-light text-gray-700 ml-5"
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "expertise.fourth_expertise_copy_html",
                  }),
                }}
              ></ul>
            </div>
            <div className="px-5 w-full lg:w-1/2 order-1 lg:order-2">
              <div className="relative w-full" style={{ paddingTop: "56.25%" }}>
                <Img
                  className="inset-0 h-full w-full"
                  style={{ position: "absolute" }}
                  fluid={data.activities.childImageSharp.fluid}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="flex flex-wrap">
          <div className="w-full md:w-1/2">
            <GoTo
              image={data.activities}
              page="/about-us/"
              title={intl.formatMessage({ id: "about.title" })}
              caption={intl.formatMessage({ id: "about.caption" })}
              button={intl.formatMessage({ id: "about.button" })}
            />
          </div>
          <div className="w-full md:w-1/2">
            <GoTo
              image={data.heroImage}
              page="/professionals/"
              title={intl.formatMessage({ id: "professionals.title" })}
              caption={intl.formatMessage({ id: "professionals.caption" })}
              button={intl.formatMessage({ id: "professionals.button" })}
            />
          </div>
        </section>
      </Layout>
    </React.Fragment>
  )
}

export default ExpertisePage

export const query = graphql`
  {
    activities: file(relativePath: { eq: "index/forest-management.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    expertise: file(relativePath: { eq: "expertise/kepakaran.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroImage: file(relativePath: { eq: "about/tentang-yayasan.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
